import { createContext, useState } from 'react';

// Create a context with default values
export const AppContext = createContext({
    state: null,
    setState: () => {}
});

export function AppProvider({ children }) {
    const [manager, setManager] = useState(null);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

    return (
        <AppContext.Provider value={{ manager, setManager, videoDimensions, setVideoDimensions }}>
            {children}
        </AppContext.Provider>
    );
}
