import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { mocap } from "../config/test_videos.js";

const TestUI = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [inputVideoUrl, setInputVideoUrl] = useState('');
  const [selectedTest, setSelectedTest] = useState('FOURSTAGEBALANCE');
  const [videoList, setVideoList] = useState([]);
  const [event, setEvent] = useState('');
  const [score, setScore] = useState('');
  const [secondsRemaining, setSecondsRemaining] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const iframeRef = useRef(null);
  const fourStageUrl = '/?test=FOURSTAGEBALANCE&duration=10';
  const sitToStandUrl = '/?test=SITTOSTAND&duration=30';

  useEffect(() => {
    const handleMessage = (e) => {
      const { msgType, msgBody } = e.data;
      if (msgType === 'activityMessage') {
        setEvent(`${msgBody.event} ${msgBody.eventDescription ? " - " + msgBody.eventDescription : ""}`);
        setScore(`${msgBody.score.bilateral} ${msgBody.score.unit}`);
        setSecondsRemaining(msgBody.secondsRemaining);
      } else if (msgType === 'cameraSize') {
        resizeIframe(msgBody);
      } else if (msgType === 'download_url_available') {
        setDownloadUrl(URL.createObjectURL(new Blob([new Uint8Array(msgBody.data)], { type: msgBody.type })));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Resize the iFrame according to what the page needs. In this case, we resize the iFrame according if the camera is landscape (laptop) or portrait (smartphone)
  const resizeIframe = (cameraSize) => {
    let width = Math.min(document.getElementById("mainCol").offsetWidth - 40, 640);
    let height = screen.height - 350;

    if (cameraSize.width >= cameraSize.height) {//landscape camera
        const newWidth = width;
        const newHeight = (cameraSize.height * width) / newWidth;
        iframeRef.style = "width:" + newWidth + "px; height:" + newHeight + "px";
    } else { //portrait camera
        const newHeight = height;
        const newWidth = (cameraSize.width * height) / newHeight;
        iframeRef.style = "width:" + newWidth + "px; height:" + newHeight + "px";
    }
  }

  const listFilesInBucket = async (activityType = null) => {
    if (!activityType) {
      return mocap;
    }

    const normalizedActivityType = activityType.trim().toLowerCase().replace(/[\s-]+/g, '');

    return mocap
      .filter((file) => {
        const fileName = file.fileName.toLowerCase().replace(/[\s-]+/g, '');
        return fileName.includes(normalizedActivityType);
      })
      .map((file) => ({
        name: file.fileName.split('/').pop(),
        url: decodeURIComponent(file.url)
      }));
  };


  const formatActivityType = (type) => {
    switch (type) {
      case 'FOURSTAGEBALANCE':
        return '4 Stage Balance';
      case 'SITTOSTAND':
        return 'Sit to Stand';
      default:
        return type;
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const formattedActivityType = formatActivityType(selectedTest);
        const files = await listFilesInBucket(formattedActivityType);

        if (files.length > 0) {
          setVideoList(files);
        } else {
          setVideoList([]);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [selectedTest]);

  const handleLoadVideo = () => {
    if (iframeRef.current) {
      let srcUrl = '';
      let suffixUrl = '';
      if (inputVideoUrl !== '' && inputVideoUrl !== null) {
        suffixUrl += `&videoUrl=${encodeURIComponent(inputVideoUrl)}`;
      } else if (videoUrl !== '' && videoUrl !== null) {
        suffixUrl += `&videoUrl=${encodeURIComponent(videoUrl)}`;
      }
      switch (selectedTest) {
        case "SITTOSTAND":
          srcUrl = `${sitToStandUrl}${suffixUrl}`;
          break;
        case "FOURSTAGEBALANCE":
          srcUrl = `${fourStageUrl}${suffixUrl}`;
          break;
        default:
          console.error("No matching activity");
          break;
      }
      iframeRef.current.src = srcUrl;
    }
  };

  const handleTestChange = (e) => {
    setSelectedTest(e.target.value.toUpperCase());
  };

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Test UI</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" />
      </Helmet>

      <div className="container">
        <main role="main" className="pb-3">
          <div className="row" id="mainCol">
            <div className="col-md-6 col-sm-12">
              <h1>Timer: <div id="timer">{secondsRemaining}</div></h1>
              <h3>Event: <div id="event">{event}</div></h3>
              <h2>Score: <div id="score">{score}</div></h2>
              <h2><a href={downloadUrl} id="downloadUrl" download>Download</a></h2>
            </div>
            <div className="col-md-6 col-sm-12">
              <select id="test-selector" className="form-select" onChange={handleTestChange} value={selectedTest}>
                <option value="FOURSTAGEBALANCE">Four Stage Balance Test</option>
                <option value="SITTOSTAND">Sit to Stand</option>
              </select>
              <div className="input-group mt-2">
              <div className="w-100">
                <select
                    className="form-select"
                    onChange={handleVideoUrlChange}
                    value={videoUrl}
                >
                  <option value="">Camera</option>
                  {videoList.length > 0 ? (
                      videoList.map((file, index) => (
                          <option key={index} value={file.url}>
                            {file.name}
                          </option>
                      ))
                  ) : (
                      <option>No videos available</option>
                  )}
                </select>
              </div>
              <div className="w-100 mt-2">
                <input
                  id='video-url-input'
                  type="text"
                  className="form-control"
                  placeholder="Or input a video URL"
                  value={inputVideoUrl}
                  onChange={(e) => setInputVideoUrl(e.target.value)}
                />
              </div>
              <button
                id="load-video"
                className="btn btn-info mt-2 w-100"
                onClick={handleLoadVideo}
              >
                Refresh Test
              </button>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 text-center mt-3">
              <iframe
                id="mocap-iframe"
                ref={iframeRef}
                src={fourStageUrl}
                allow="autoplay; camera; microphone"
                title="Mocap"
                style={{ width: '100%', height: '80vh', border: 'none' }}
              ></iframe>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TestUI;
