import { useRef, useEffect, useContext } from 'react';
import { AppContext } from "../app-context";
import Home from "./Home";
import { downloadJSON } from "../utils/download";

const CapturePoints = () => {
    const { manager, videoDimensions } = useContext(AppContext);
    const allPoses = useRef([]);
    let currentState = '';
    const stateChanges = useRef([{
        event: 'begin_pose_capture',
        i: 0,
    }]);
    let startCaptureTime = performance.now();

    const messageEventHandler = (e) => {
        if (e.data.msgBody.event !== currentState) {
            currentState = e.data.msgBody.event;
            const stateLog = {
                event: e.data.msgBody.event,
                i: allPoses.current.length,
            };
            stateChanges.current.push(stateLog);
        }
    };

    useEffect(() => {
        window.addEventListener('message', messageEventHandler);
        return () => { window.removeEventListener('message', messageEventHandler) };
    }, []);

    const frameCallback = (poses) => {
        if (poses && poses.length > 0) {
            poses.forEach((pose) => {
                pose.t = performance.now() - startCaptureTime;
            });
            allPoses.current.push(poses);
        }
    };

    const startCapture = () => {
        if (manager && manager.frameCallbacks) {
            manager.frameCallbacks.push(frameCallback);
        } else {
            alert("Error: unable to begin capture.");
        }
    };

    const endCapture = () => {
        if (manager && manager.frameCallbacks) {
            const index = manager.frameCallbacks.indexOf(frameCallback);
            if (index > -1) {
                manager.frameCallbacks.splice(index, 1);
            }
        }

        const data = {
            width: videoDimensions.width,
            height: videoDimensions.height,
            stateChanges: stateChanges.current,  // Use `current` for the final data
            poses: allPoses.current,
        };
        downloadJSON(data, `pose-${videoDimensions.width}x${videoDimensions.height}.json`);
    };

    useEffect(() => {
        if (manager && manager.frameCallbacks && !manager.frameCallbacks.includes(frameCallback)) {
            manager.frameCallbacks.push(frameCallback);
        }
    }, [manager]);

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <button onClick={startCapture}>Start Capture</button>
                <button onClick={endCapture} style={{ marginLeft: '10px' }}>End Capture</button>
            </div>
            <Home />
        </div>
    );
};

export default CapturePoints;
