import '@tensorflow/tfjs-backend-webgl';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import 'regenerator-runtime/runtime';
import * as posedetection from '@tensorflow-models/pose-detection';
tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);
import { Video } from './video/video';
import { Camera } from './camera/camera';
import { setupDatGui } from './camera/option_panel';
import { STATE } from '../config/params';
import { setBackendAndEnvFlags } from '../utils/device';
import { setupStats } from './camera/stats_panel';
import { MediaPipeModel } from './models/mediapipe';
let detector, camera, stats;
let startInferenceTime, numInferences = 0;
let inferenceTimeSum = 0, lastPanelUpdate = 0;
let rafId;
let testName = '';
let testDuration = '';
const frameCallbacks = [];

export async function setUp(model, type, testName, testDuration, encodedUrl) {
    await setupDatGui(model, type);
    stats = setupStats();
    if (encodedUrl != "null") {
        camera = await Video.setupVideo(encodedUrl, testName, testDuration);
    }
    else {
        camera = await Camera.setupCamera(STATE.camera, testName, testDuration);
    }
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    detector = await createDetector();
    renderPrediction(encodedUrl);
    return {frameCallbacks, camera, detector, stats};
}

async function createDetector() {
    const runtime = STATE.backend.split('-')[0];
    let modelType;
    switch (STATE.model) {
        case posedetection.SupportedModels.PoseNet:
            return posedetection.createDetector(STATE.model, {
                quantBytes: 4,
                architecture: 'MobileNetV1',
                outputStride: 16,
                inputResolution: { width: 500, height: 500 },
                multiplier: 0.75
            });
        case posedetection.SupportedModels.BlazePose:
            if (runtime === 'mediapipe') {
                let mpModel = new MediaPipeModel();
                await mpModel.setupModel();
                return mpModel;
            } else if (runtime === 'tfjs') {
                return posedetection.createDetector(STATE.model, { runtime, modelType: STATE.modelConfig.type });
            }
            else {
                throw new Error('Invalid runtime');
            }
        case posedetection.SupportedModels.MoveNet:
            if (STATE.modelConfig.type == 'lightning') {
                modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
            } else if (STATE.modelConfig.type == 'thunder') {
                modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
            } else if (STATE.modelConfig.type == 'multipose') {
                modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
            }
            if (STATE.modelConfig.customModel !== '') {
                modelType.modelUrl = STATE.modelConfig.customModel;
            }
            if (STATE.modelConfig.type === 'multipose') {
                modelType.enableTracking = STATE.modelConfig.enableTracking;
            }
            return posedetection.createDetector(STATE.model, modelType);
    }
}

async function checkGuiUpdate(encodedUrl) {
    if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
        if (encodedUrl != "null") {
            camera = await Video.setupVideo(encodedUrl, testName, testDuration);
        }
        else {
            camera = await Camera.setupCamera(STATE.camera, testName, testDuration);
        }
        STATE.isTargetFPSChanged = false;
        STATE.isSizeOptionChanged = false;
    }
    if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
        STATE.isModelChanged = true;
        window.cancelAnimationFrame(rafId);
        if (detector != null) {
            detector.dispose();
        }
        if (STATE.isFlagChanged || STATE.isBackendChanged) {
            await setBackendAndEnvFlags(STATE.flags, STATE.backend);
        }
        try {
            detector = await createDetector();
        }
        catch (error) {
            detector = null;
            alert(error);
        }
        STATE.isFlagChanged = false;
        STATE.isBackendChanged = false;
        STATE.isModelChanged = false;
    }
}

function beginEstimatePosesStats() {
    startInferenceTime = (performance || Date).now();
}

function endEstimatePosesStats() {
    const endInferenceTime = (performance || Date).now();
    inferenceTimeSum += endInferenceTime - startInferenceTime;
    ++numInferences;
    const panelUpdateMilliseconds = 1000;
    if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
        const averageInferenceTime = inferenceTimeSum / numInferences;
        inferenceTimeSum = 0;
        numInferences = 0;
        stats.customFpsPanel.update(1000.0 / averageInferenceTime, 120 /* maxValue */);
        lastPanelUpdate = endInferenceTime;
    }
}

async function renderResult() {
    if (camera.video.readyState < 2) {
        await new Promise((resolve) => {
            camera.video.onloadeddata = () => {
                resolve(video);
            };
        });
    }
    let poses = null;
    // Detector can be null if initialization failed (for example when loading
    // from a URL that does not exist).
    if (detector != null) {
        // FPS only counts the time it takes to finish estimatePoses.
        beginEstimatePosesStats();
        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
            if (detector instanceof MediaPipeModel) {
                await detector.predict(camera.video);
                poses = detector.mappedKeypoints;
            } else {
                poses = await detector.estimatePoses(
                    camera.video,
                    {
                        maxPoses: STATE.modelConfig.maxPoses,
                        flipHorizontal: false
                    });
            }
        } catch (error) {
            detector.dispose();
            detector = null;
            alert(error);
        }
        for (let i = 0; i < frameCallbacks.length; ++i) {
            frameCallbacks[i](poses);
        }
        endEstimatePosesStats();
    }
    camera.drawCtx();
    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (poses && poses.length > 0 && !STATE.isModelChanged) {
        camera.drawResults(poses);
    }
}

async function renderPrediction(encodedUrl) {
    await checkGuiUpdate(encodedUrl);
    if (!STATE.isModelChanged) {
        await renderResult();
    }
    rafId = requestAnimationFrame(renderPrediction);
};
