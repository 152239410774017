import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import TestUI from "./pages/TestUI";
import { AppProvider } from "./app-context";
import CapturePoints from "./pages/CapturePoints";  // Import the AppProvider

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="test" element={<TestUI />} />
                    <Route path="capture-points" element={<CapturePoints />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = createRoot(document.getElementById("root"));
root.render(
    <AppProvider>
        <App />
    </AppProvider>
);
