import { setUp } from '../core/manager';
import { useRef, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from "../app-context";

async function configure(encodedUrl) {
  // Gui content will change depending on which model is in the query string.
  const urlParams = new URLSearchParams(window.location.search);
  let model = urlParams.get('model') || 'blazepose';
  let type = urlParams.get('type') || null;
  let testName = urlParams.get('test') || '';
  let testDuration = urlParams.get('duration') || 0;
  return await setUp(model, type, testName, testDuration, encodedUrl);

}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Home = () => {
  const videoRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null);
  const query = useQuery();
  const url = query.get('videoUrl');
  const { setManager, setVideoDimensions } = useContext(AppContext);

  const handleMetadataLoaded = () => {
    const video = videoRef.current;
    if (video) {
      setVideoDimensions({ width: video.videoWidth, height: video.videoHeight });
    }
  };


  useEffect(() => {
    const encodedUrl = encodeURI(url);
    setVideoFile(encodedUrl);
    configure(encodedUrl).then((res) => {
      setManager(res);
    });
  }, [url]);

  return (<>
    <div id="stats"></div>
    <div id="main">
      <div className="container">
        <div className="canvas-wrapper">
          <div hidden id="activity" className="status-text"></div>
          <div style={{ position: 'relative' }}>
            <video ref={videoRef} src={videoFile} id="video" playsInline muted style={{ transform: 'scaleX(-1)', WebkitTransform: 'scaleX(-1)' }} onLoadedMetadata={handleMetadataLoaded}></video>
            <canvas id="output" style={{ position: 'absolute', left: '0', top: '0' }}></canvas>
          </div>
        </div>
        <div id="scatter-gl-container"></div>
      </div>
    </div>
  </>);
};

export default Home;
