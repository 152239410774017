/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from '../../../config/params';
import * as mathUtils from '../../../utils/math';
import * as puppet from '../puppet';
import {isMobile} from '../../../utils/device';
import * as messageService from '../../../services/message_service';
import { checkGeneralFall } from '../activity_picker';

const thresholdStartPoint = 0.75;
const valueNotDefined = -1;

let holdStartPositionCount = 0;
let assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
let currentUserEvent = params.ASSESSMENT_POSITION.NOT_STARTED;

let timeToHoldFourStage = 10;
let currentActivity = '';
let score = valueNotDefined;
let finishDate;
let heightThreshold = valueNotDefined;
let frameHeight = undefined;
let frameWidth = undefined;
let inFrameWeight = valueNotDefined;

function setActivityMessage(event = '', currentScore = valueNotDefined, eventDescription = null){

    const secondsRemaining = calculateSecondsRemaining();

    if (currentScore > score) {
        score = currentScore;
    }

    const activityScore = messageService.ActivityScore(null, null, score, params.UNITS_SCORE.SECONDS);
    const msg = messageService.MessageBody(currentActivity, event, eventDescription, activityScore, secondsRemaining);

    messageService.sendActivityMessage(params.MESSAGE_TYPE.ACTIVITY, msg);
}

function calculateSecondsRemaining() {
    if (finishDate == undefined) {
        return null;
    } else {
        const currentDate = new Date();
        const timeDifference = (finishDate.getTime() - currentDate.getTime()) / 1000;
        return Math.ceil(timeDifference);
    }
}

function hasFinishedTimeExpected() {
    const secondsRemaining = calculateSecondsRemaining();
    return (secondsRemaining < 0 && secondsRemaining != null);
}

function poseDetectionFS(keypoints, activityName, activityDuration, source, videoHeight, videoWidth) {
    frameHeight = videoHeight;
    frameWidth = videoWidth;
    if (activityDuration === null || activityDuration == 0) {
        activityDuration = 10;
    }
    currentActivity = activityName;
    timeToHoldFourStage = activityDuration;
    switch (assessmentPosition) {
        case params.ASSESSMENT_POSITION.NOT_STARTED:
            holdStartPositionCount = 0;
            validateUserIsInFrame(keypoints);
            break;
        case params.ASSESSMENT_POSITION.FOUR_STEP_BALANCE:
            fourStageBalance(keypoints);
            break;
        case params.ASSESSMENT_POSITION.COMPLETE:
            score = valueNotDefined;
            finishDate = undefined;
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            break;
    }
}

function getUowKneeHip(keypoints) {
    const kneePoint = (keypoints[params.KEYPOINTS.LEFT_KNEE].y) + Math.abs(keypoints[params.KEYPOINTS.RIGHT_KNEE].y) / 2;
    const hipPoint = (keypoints[params.KEYPOINTS.LEFT_HIP].y) + Math.abs(keypoints[params.KEYPOINTS.RIGHT_HIP].y) / 2;
    return parseFloat(Math.abs(((kneePoint - hipPoint) / 4).toFixed(2)));
}

function validateUserIsInFrame(keypoints) {
    const CLOSE_MARGIN_THRESHOLD = frameHeight * 0.03;

    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const rHand = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lHand = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];

    function validateBetweenLimits(keypoint) {
      const upperLimit = CLOSE_MARGIN_THRESHOLD;
      const bottomLimit = (frameHeight - CLOSE_MARGIN_THRESHOLD);
      const leftLimit = frameWidth * 0.05;
      const rightLimit = frameWidth - leftLimit;
      const isBetweenY = keypoint.y > upperLimit && keypoint.y < bottomLimit;
      const isBetweenX = keypoint.x > leftLimit && keypoint.x < rightLimit;
      return isBetweenX && isBetweenY;
    }

    const arePointsInFrame = validateBetweenLimits(nose) && validateBetweenLimits(lAnkle)
        && validateBetweenLimits(rAnkle) && validateBetweenLimits(rHand)
        && validateBetweenLimits(lHand) && validateBetweenLimits(rHip)
        && validateBetweenLimits(lHip);

    if (arePointsInFrame) {
        inFrameWeight++;
    } else {
        inFrameWeight = 0;
        setActivityMessage(params.EVENTS.NOT_IN_FRAME);
        currentUserEvent = params.EVENTS.NOT_IN_FRAME;
        assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
    }

    const inFrameWeightThreshold = isMobile() ? 50 : 80;
    if (inFrameWeight > inFrameWeightThreshold) {
        setActivityMessage(params.EVENTS.IN_FRAME);
        currentUserEvent = params.EVENTS.IN_FRAME;
        assessmentPosition = params.ASSESSMENT_POSITION.FOUR_STEP_BALANCE;
    }
}

// -----------------------IN POSITION - START

function riseHandToStart(keypoints) {
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;
    const rWristY = keypoints[params.KEYPOINTS.RIGHT_WRIST].y;
    const head = keypoints[params.KEYPOINTS.NOSE];
    const hasAtLeastOneArmRaised = lWristY <= head.y || rWristY <= head.y;
    if (hasAtLeastOneArmRaised) {
        userStartedTest = true;
        setActivityMessage(params.EVENTS.COUNTDOWN, 0);
        currentUserEvent = params.EVENTS.COUNTDOWN;
        heightThreshold = keypoints[params.KEYPOINTS.LEFT_HIP].y;
    }
}

function cleanInterStage() {
    currentStage++;
    finishDate = undefined;
    userStartedTest = false;
    assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
    currentUserEvent = params.EVENTS.NOT_IN_FRAME;
    holdStartPositionCount = 0;
}

// -----------------------FOUR STEP BALANCE - START
let currentStage = 1;
const maxWeightForFailure = isMobile() ? 50 : 80;
let currentFailureWeight = 0;
let currentTimerScore = 0;
let userStartedTest = false;
const timeForInstructions = 5;
let patientHasFallen = false;
let patientHasAborted = false;

function fourStageBalance(keypoints) {

    if (!userStartedTest) {
        validateUserIsInFrame(keypoints);
        if (currentUserEvent === params.EVENTS.NOT_IN_FRAME) {
            return;
        }
        switch (currentStage) {
            case 1:
                firstPositionCheckInPosition(keypoints);
                break;
            case 2:
                secondPositionCheckInPosition(keypoints);
                break;
            case 3:
                thirdPositionCheckInPosition(keypoints);
                break;
            case 4:
                fourthPositionCheckInPosition(keypoints);
            break;
        }
        if (currentUserEvent === params.EVENTS.IN_POSITION) {
            riseHandToStart(keypoints);
        }

        return;
    }

    if (checkGeneralFall(keypoints, heightThreshold)) {// Once we merge branch 32, this validation goes to camera.
        patientHasFallen = true;
    }

    if (currentFailureWeight >= maxWeightForFailure) {
        patientHasAborted = true;
    }

    if (currentUserEvent === params.EVENTS.COUNTDOWN) {
        if (finishDate === undefined) {
            finishDate = new Date();
            finishDate.setSeconds(finishDate.getSeconds() + timeForInstructions);
        }
        if (hasFinishedTimeExpected()) {
            finishDate = undefined;
            setActivityMessage(params.EVENTS.STARTED, 0);
            currentUserEvent = params.EVENTS.STARTED;
        } else {
            setActivityMessage(params.EVENTS.COUNTDOWN);
            currentUserEvent = params.EVENTS.COUNTDOWN;
        }
    } else {
        if (finishDate !== undefined && currentStage > 0) {
            const secsRemaining = calculateSecondsRemaining();
            if (secsRemaining >= 0){
                currentTimerScore = ((currentStage - 1) * timeToHoldFourStage) + (timeToHoldFourStage - secsRemaining);
            }
        }
        setActivityMessage(params.EVENTS.STARTED, currentTimerScore);
        currentUserEvent = params.EVENTS.STARTED;

        if (patientHasAborted) {
            setActivityMessage(params.EVENTS.ABORTED);
            finishDate = undefined;
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            currentUserEvent = params.EVENTS.ABORTED;  
            return;
        }
        if (patientHasFallen) {
            finishDate = undefined;
            setActivityMessage(params.EVENTS.FALL, null, "More than 60% of the upper trunk is below fall height limit");
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            currentUserEvent = params.EVENTS.FALL;
            return;
        }
    }

    switch (currentStage) {
        case 1://Two feet together
            firstPositionCalculation(keypoints);
            break;
        case 2://Instep
            secondPositionCalculation(keypoints);
            break;
        case 3://TandemStand
            thirdPositionCalculation(keypoints);
            break;
        case 4://OneFoot
            fourthPositionCalculation(keypoints);
        break;
    }
}

function firstPositionCheckInPosition(keypoints) {
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);
    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.3));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);

    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.8));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));
    const toesAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && toesAreCloseX;

    const firstPosition = ankleSameLevelY && footAreCloseX && anklesToeCloseX;

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);

    const timeToHold = isMobile() ? 20 : 50;
    const waitedTimeToStart = holdStartPositionCount > timeToHold;

    if (firstPosition && isFrontal) {
        holdStartPositionCount ++;
    } else {
        holdStartPositionCount = 0;
    }

    if (waitedTimeToStart) {
        currentUserEvent = params.EVENTS.IN_POSITION;
        setActivityMessage(params.EVENTS.IN_POSITION);
    } else {
        currentUserEvent = params.EVENTS.IN_FRAME;
    }
}

function firstPositionCalculation(keypoints) {
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);
    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.3));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);

    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.8));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));
    const toesAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && toesAreCloseX;

    const firstPosition = ankleSameLevelY && footAreCloseX && anklesToeCloseX;

    if (firstPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }

    if (hasFinishedTimeExpected()) {
        cleanInterStage();
        return;
    }

    const toesAreCloseishX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, (uow * 1.1));

    const failureCondition = (finishDate !== undefined && (!ankleSameLevelY || !anklesAreCloseX || !toesAreCloseishX));
    if (failureCondition) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

function secondPositionCheckInPosition(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);

    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.65));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);
    
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.85));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));
    const toesAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && toesAreCloseX;
    const footIsAtDistanceY = Math.abs(rFootIndex.y - lFootIndex.y) > uow * 0.09;

    const secondPosition = ankleSameLevelY && footAreCloseX && anklesToeCloseX && footIsAtDistanceY;
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);

    const timeToHold = isMobile() ? 20 : 50;
    const waitedTimeToStart = holdStartPositionCount > timeToHold;

    if (secondPosition && isFrontal) {
        holdStartPositionCount ++;
    } else {
        holdStartPositionCount = 0;
    }

    if (waitedTimeToStart) {
        currentUserEvent = params.EVENTS.IN_POSITION;
        setActivityMessage(params.EVENTS.IN_POSITION);
    } else {
        currentUserEvent = params.EVENTS.IN_FRAME;
    }
}

function secondPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);

    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.65));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);

    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.85));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));
    const toesAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && toesAreCloseX;

    const footIsAtDistanceY = Math.abs(rFootIndex.y - lFootIndex.y) > uow * 0.09;

    const secondPosition = ankleSameLevelY && footAreCloseX && anklesToeCloseX && footIsAtDistanceY;

    if (secondPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }

    if (hasFinishedTimeExpected()) {
        cleanInterStage();
        return;
    }

    const anklesAreCloseishX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.9));
    const toesAreCloseishX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, (uow * 1.1));
    const footIsAtSomeDistanceY = Math.abs(rFootIndex.y - lFootIndex.y) > uow * 0.06;

    if (finishDate === undefined) {
        return;
    }

    if ((!anklesAreCloseishX || !ankleSameLevelY || !toesAreCloseishX)) {
        currentFailureWeight++;
    } else if (!footIsAtSomeDistanceY) {
        currentFailureWeight += 0.5;
    } else {
        currentFailureWeight = 0;
    }
}

function thirdPositionCheckInPosition(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 1.3));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, (uow * 1.3)) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, (uow * 1.3));
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.8));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));

    const footAreCloseX = anklesAreCloseX && heelAreCloseX && anklesToeCloseX;

    const kneesAreCloseX = mathUtils.arePointsClose(rKnee.x, lKnee.x, (uow * 1.05));

    const thirdPosition = footAreCloseX && areAnklesSameLevelY && kneesAreCloseX;

    const timeToHold = isMobile() ? 20 : 50;
    const waitedTimeToStart = holdStartPositionCount > timeToHold;

    if (thirdPosition) {
        holdStartPositionCount ++;
    } else {
        holdStartPositionCount = 0;
    }

    if (waitedTimeToStart) {
        currentUserEvent = params.EVENTS.IN_POSITION;
        setActivityMessage(params.EVENTS.IN_POSITION);
    } else {
        currentUserEvent = params.EVENTS.IN_FRAME;
    }
}

function thirdPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = getUowKneeHip(keypoints);
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 1.3));
    const anklesToeCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, (uow * 1.3)) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, (uow * 1.3));
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.8));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, (uow * 0.8));

    const footAreCloseX = anklesAreCloseX && heelAreCloseX && anklesToeCloseX;

    const kneesAreCloseX = mathUtils.arePointsClose(rKnee.x, lKnee.x, (uow * 1.05));

    const thirdPosition = footAreCloseX && areAnklesSameLevelY && kneesAreCloseX;

    if (thirdPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }
    if (hasFinishedTimeExpected()) {
        cleanInterStage();
        return;
    }

    const kneesAreCloseishX = mathUtils.arePointsClose(rKnee.x, lKnee.x, (uow * 1.2));
    const anklesAreCloseishX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.95));

    if (finishDate !== undefined && (!anklesAreCloseishX || !kneesAreCloseishX)) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

function fourthPositionCheckInPosition(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];

    const uow = getUowKneeHip(keypoints);
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.8));
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.3));

    const forthPosition = !anklesAreCloseX && !areAnklesSameLevelY;

    const timeToHold = isMobile() ? 20 : 50;
    const waitedTimeToStart = holdStartPositionCount > timeToHold;

    if (forthPosition) {
        holdStartPositionCount ++;
    } else {
        holdStartPositionCount = 0;
    }

    if (waitedTimeToStart) {
        currentUserEvent = params.EVENTS.IN_POSITION;
        setActivityMessage(params.EVENTS.IN_POSITION);
    } else {
        currentUserEvent = params.EVENTS.IN_FRAME;
    }
}

function fourthPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];

    const uow = getUowKneeHip(keypoints);
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.8));
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.3));

    const forthPosition = !anklesAreCloseX && !areAnklesSameLevelY;

    if (forthPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        setActivityMessage(params.EVENTS.FINISHED);
        assessmentPosition = params.ASSESSMENT_POSITION.COMPLETE;
        currentUserEvent = params.EVENTS.FINISHED;
        return;
    }
    if (finishDate !== undefined && !forthPosition) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

module.exports = poseDetectionFS;
