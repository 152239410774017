/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as posedetection from '@tensorflow-models/pose-detection';
import * as scatter from 'scatter-gl';

import * as params from '../../config/params';
import { isMobile } from '../../utils/device';

import { getChestPoint, connectChestToNose} from '../mocap/puppet';

const activityPicker = require('../mocap/activity_picker').default;

let activityName = '';
let activityDuration = '';
export class Camera {
  constructor() {
    this.video = document.getElementById('video');
    this.canvas = document.getElementById('output');
    this.ctx = this.canvas.getContext('2d');
    this.scatterGLEl = document.querySelector('#scatter-gl-container');
    this.scatterGL = new scatter.ScatterGL(this.scatterGLEl, {
      'rotateOnStart': true,
      'selectEnabled': false,
      'styles':{polyline:{defaultOpacity: 1, deselectedOpacity: 1}}
    });
    this.scatterGLHasInitialized = false;
    this.mediaType = null;
    this.extension = null;
    this.recordedBlobs = [];
    this.mediaRecorder = null;
  }

  /**
   * Initiate a Camera instance and wait for the camera stream to be ready.
   * @param cameraParam From app `STATE.camera`.
   */
  static async setupCamera(cameraParam, testName, testDuration) {
    const camera = new Camera();

    // pick the correct video type
    const potentialMediaTypes = [
      'video/mp4',
      'video/webm;codecs:h264,opus'
    ];
    camera.mediaType = potentialMediaTypes.find(MediaRecorder.isTypeSupported);
    if (camera.mediaType) { // <2>
      camera.extension = camera.mediaType.split(';')[0].split('/')[1];
    }

    if (!navigator.mediaDevices?.getUserMedia) {
      throw new Error(
          'Browser API navigator.mediaDevices.getUserMedia not available');
    }
    activityName = testName;
    activityDuration = testDuration;

    const {targetFPS, sizeOption} = cameraParam;
    const $size = params.VIDEO_SIZE[sizeOption];
    const mobileSize = params.MOBILE_VIDEO_SIZE;
    const videoConfig = {
      'audio': false,
      'video': {
        facingMode: 'user',
        width: isMobile() ? mobileSize.width : $size.width,
        height: isMobile() ? mobileSize.height : $size.height,
        frameRate: {
          ideal: targetFPS,
        }
      }
    };

    const stream = await navigator.mediaDevices.getUserMedia(videoConfig).then((stream) => {
      if (stream === undefined) {
        return Promise.reject(new Error("Unable to initialize media stream"));
      }

      // create a recorder to capture the video
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: camera.mediaType,
        audioBitsPerSecond : 64000,
        videoBitsPerSecond: 3000000,
      });

      // buffer the video data as it is received
      this.mediaRecorder.addEventListener('dataavailable', event => {
        // store the data
        if (event.data && event.data.size > 0) {
          camera.recordedBlobs.push(event.data);
        }
      });

      // get a chunk of data every second
      this.mediaRecorder.start(1*1000);

      // pass the original stream to the caller
      return Promise.resolve(stream);
    });

    camera.video.srcObject = stream;

    await new Promise((resolve) => {
      camera.video.onloadedmetadata = () => {
        resolve(video);
      };
    });

    // wait for a completed test and send the data to the parent frame
    window.addEventListener('message', event => {
      if (event.origin == window.origin) {
        if (['finished', 'aborted', 'fall'].includes(event.data.msgBody.event)) {
          this.mediaRecorder.stop();
          const blob = camera.getVideoDownloadBlob();
          blob.arrayBuffer().then(data => {
            const transferObj = { msgType: 'download_url_available', msgBody: { data: data, type: blob.type } };
            parent.postMessage(transferObj, "*", [data]);
          });
        }
      }
    });

    camera.video.play();

    const videoWidth = params.COMPONENT_SIZE.width;
    const videoHeight = params.COMPONENT_SIZE.height;

    camera.canvas.width = videoWidth;
    camera.canvas.height = videoHeight;
    const canvasContainer = document.querySelector('.canvas-wrapper');
    canvasContainer.style = `width: ${videoWidth}px; height: ${videoHeight}px`;

    camera.video.width = videoWidth;
    camera.video.height = videoHeight;
    camera.video.style = `width: ${videoWidth}px; height: ${videoHeight}px`;

    // Notify the parent of the camera size so it can resize the container.
    parent.postMessage({msgType: "cameraSize", msgBody: {width: videoWidth, height: videoHeight}},"*");

    // Because the image from camera is mirrored, need to flip horizontally.
    camera.ctx.translate(videoWidth, 0);
    camera.ctx.scale(-1, 1);

    camera.scatterGLEl.style =
        `width: ${videoWidth*2}px; height: ${videoHeight*2}px;`;
    camera.scatterGL.resize();

    camera.scatterGLEl.style.display =
        params.STATE.modelConfig.render3D ? 'inline-block' : 'none';

    return camera;
  }

  drawCtx() {
    this.ctx.drawImage(this.video, 0, 0, this.video.width, this.video.height);
  }

  clearCtx() {
    this.ctx.clearRect(0, 0, this.video.width, this.video.height);
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param poses A list of poses to render.
   */
  drawResults(poses) {
    if (!poses) { return };
    for (const pose of poses) {
      this.drawResult(pose);
    }
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param pose A pose with keypoints to render.
   */
  drawResult(pose) {
    if (pose.keypoints != null) {
        this.drawKeypoints(pose.keypoints);
        this.drawSkeleton(pose.keypoints, pose.id);
        activityPicker(pose.keypoints, activityName, activityDuration, "camera", this.video.height, this.video.width);
    }

    if (pose.keypoints3D != null && params.STATE.modelConfig.render3D) {
        this.drawKeypoints3D(pose.keypoints3D);
    }
  }

  /**
   * Draw the keypoints on the video.
   * @param keypoints A list of keypoints.
   */
  drawKeypoints(keypoints) {
    const keypointInd = posedetection.util.getKeypointIndexBySide(params.STATE.model);

    this.ctx.fillStyle = params.COLORS.POINTS;
    this.ctx.strokeStyle = params.COLORS.DEFAULT;
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    for (const i of keypointInd.middle) {
      this.drawKeypoint(keypoints[i]);
    }

    for (const i of keypointInd.left) {
        switch (i) {
            case params.KEYPOINTS.LEFT_WRIST:
            case params.KEYPOINTS.LEFT_SHOULDER:
            case params.KEYPOINTS.LEFT_ELBOW:
            case params.KEYPOINTS.LEFT_HIP:
            case params.KEYPOINTS.LEFT_KNEE:
            case params.KEYPOINTS.LEFT_ANKLE:
            case params.KEYPOINTS.LEFT_HEEL:
            case params.KEYPOINTS.LEFT_FOOT_INDEX:
                this.drawKeypoint(keypoints[i]);
                break;
        }
    }

    for (const i of keypointInd.right) {
        switch (i) {
            case params.KEYPOINTS.RIGHT_WRIST:
            case params.KEYPOINTS.RIGHT_SHOULDER:
            case params.KEYPOINTS.RIGHT_ELBOW:
            case params.KEYPOINTS.RIGHT_HIP:
            case params.KEYPOINTS.RIGHT_KNEE:
            case params.KEYPOINTS.RIGHT_ANKLE:
            case params.KEYPOINTS.RIGHT_HEEL:
            case params.KEYPOINTS.RIGHT_FOOT_INDEX:
                this.drawKeypoint(keypoints[i]);
                break;
        }
    }

    const leftShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rightShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const chestPoint = getChestPoint(leftShoulder, rightShoulder);
    if (chestPoint) {
        this.drawKeypoint(chestPoint);
    }

    if (chestPoint && keypoints[params.KEYPOINTS.NOSE]) {
        connectChestToNose(chestPoint, keypoints[params.KEYPOINTS.NOSE], this.ctx);
    }
  }

  drawKeypoint(keypoint) {
    const score = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

    if (score >= scoreThreshold) {
        const circle = new Path2D();
        circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
        this.ctx.fill(circle);
    }
  }

  /**
   * Draw the skeleton of a body on the video.
   * @param keypoints A list of keypoints.
   */
  drawSkeleton(keypoints, poseId) {
    const color = params.STATE.modelConfig.enableTracking && poseId != null ?
        params.COLOR_PALETTE[poseId % 20] :
        'White';

    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    const drawLineIfValid = (kp1, kp2) => {
        const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

        if ((kp1.score ?? 1) >= scoreThreshold && (kp2.score ?? 1) >= scoreThreshold) {
            this.ctx.beginPath();
            this.ctx.moveTo(kp1.x, kp1.y);
            this.ctx.lineTo(kp2.x, kp2.y);
            this.ctx.stroke();
        }
    };

    const omittedConnections = new Set([
        params.KEYPOINTS.LEFT_EAR, params.KEYPOINTS.RIGHT_EAR,
        params.KEYPOINTS.LEFT_MOUTH, params.KEYPOINTS.RIGHT_MOUTH,
        params.KEYPOINTS.LEFT_WRIST, params.KEYPOINTS.RIGHT_WRIST
    ]);

    posedetection.util.getAdjacentPairs(params.STATE.model).forEach(([i, j]) => {
      const kp1 = keypoints[i];
      const kp2 = keypoints[j];


      const isValidConnection =
          (i === params.KEYPOINTS.CHEST || j === params.KEYPOINTS.CHEST ||
          (i >= params.KEYPOINTS.LEFT_SHOULDER && i <= params.KEYPOINTS.RIGHT_HEEL) ||
          (j >= params.KEYPOINTS.LEFT_SHOULDER && j <= params.KEYPOINTS.RIGHT_HEEL));

      if (isValidConnection && !omittedConnections.has(i) && !omittedConnections.has(j) && i != 18 && j != 19) {
          drawLineIfValid(kp1, kp2);
      }

      if (
        (i === params.KEYPOINTS.LEFT_ELBOW && j === params.KEYPOINTS.LEFT_WRIST) ||
        (i === params.KEYPOINTS.RIGHT_ELBOW && j === params.KEYPOINTS.RIGHT_WRIST)
      ) {
        drawLineIfValid(kp1, kp2);
      }
    });
  }

  drawKeypoints3D(keypoints) {
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
    const pointsData =
        keypoints.map(keypoint => ([-keypoint.x, -keypoint.y, -keypoint.z]));

    const dataset =
        new scatter.ScatterGL.Dataset([...pointsData, ...params.ANCHOR_POINTS]);

    const keypointInd =
        posedetection.util.getKeypointIndexBySide(params.STATE.model);
    this.scatterGL.setPointColorer((i) => {
      if (keypoints[i] == null || keypoints[i].score < scoreThreshold) {
        // hide anchor points and low-confident points.
        return params.COLORS.DEFAULT;
      }
      if (i === 0) {
        return params.COLORS.POINTS // '#ff0000' /* Red */;
      }
      if (keypointInd.left.indexOf(i) > -1) {
        return params.COLORS.POINTS //'#00ff00' /* Green */;
      }
      if (keypointInd.right.indexOf(i) > -1) {
        return params.COLORS.POINTS //'#ffa500' /* Orange */;
      }
    });

    if (!this.scatterGLHasInitialized) {
      this.scatterGL.render(dataset);
    } else {
      this.scatterGL.updateDataset(dataset);
    }
    const connections = posedetection.util.getAdjacentPairs(params.STATE.model);
    const sequences = connections.map(pair => ({indices: pair}));
    this.scatterGL.setSequences(sequences);
    this.scatterGLHasInitialized = true;
  }

  getVideoDownloadBlob() {
    return new Blob(this.recordedBlobs, {type: this.mediaType});
  }

}
